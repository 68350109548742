<template>
  <v-row class="mb-0 d-inherit h-100">
    <v-col class="flex-0 pb-0 pt-0">
      <div class="white-back-color py-3 px-4 flex-1">
        <v-row class="m-0 align-items-center">
          <el-button
            dense
            class="t-btn"
            :class="{
              active:
                !$route.query.tab || ['clients'].includes($route.query.tab)
            }"
            @click="setTableTab('clients')"
            >Mijozlar</el-button
          >
          <el-button
            dense
            class="t-btn"
            :class="{ active: ['providers'].includes($route.query.tab) }"
            @click="setTableTab('providers')"
            >Yetqazib beruvchilar</el-button
          >
        </v-row>
      </div>
    </v-col>

    <v-col class="py-0 flex-0">
      <el-table
        ref="tableRef"
        :data="list"
        border
        size="small"
        v-loading="table_loading"
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column
          align="center"
          width="180"
          prop="full_name"
          label="Tashkilot"
        />

        <el-table-column align="center" width="180" prop="inn" label="INN" />

        <el-table-column
          align="center"
          width="180"
          prop="ostatka"
          label="Qoldiq (ostatka)"
        >
          <template slot-scope="scope">
            {{ numberWithSpaces(scope.row.ostatka) }}
          </template>
        </el-table-column>
      </el-table>

      <div style="background: #fff">
        <div
          ref="jamiItogoRef"
          class="flex-0 d-flex font-606266 font-12"
          style="border-right: 1px solid #ebeef5"
        >
          <div
            class="flex-1 text-center font-12 p-2"
            style="border-left: 1px solid #ebeef5"
          >
            Barchasi
          </div>
          <div class="flex-1 p-2" style="border-left: 1px solid #ebeef5"></div>
          <div
            class="flex-1 text-center font-12 p-2"
            style="border-left: 1px solid #ebeef5"
          >
            <span class="font-12">{{
              numberWithSpaces(reportsData.total)
            }}</span>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import paginationMixin from '@/mixins/pagination.mixin.js'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { takeFilledKeyValue, numberWithSpaces } from '@/utils'

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth() + 1

export default {
  data() {
    return {
      filterFormProps: {}
    }
  },

  mixins: [paginationMixin],

  computed: {
    ...mapState({
      reportsData: (state) => state.reportsStore.reportsData || {},
      table_loading: (state) => state.reportsStore.table_loading || false,
      list: (state) => (state.reportsStore.reportsData || {}).results || []
    }),

    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },

  methods: {
    ...mapActions(['report29']),
    numberWithSpaces,

    fetchData() {
      const routeParmas = takeFilledKeyValue(this.$route.query)

      // eslint-disable-next-line no-unused-vars
      const { page, limit, offset, tab, ...otherProps } = routeParmas

      this.report29({
        endpoint: tab || 'clients',
        params: {
          offset: this.$_pageOffset,
          limit: this.$_perPage,
          ...(this.filterFormProps || {}),
          ...(otherProps || {})
        }
      })
    },

    setTableTab(tabName) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          tab: tabName
        }
      })
    },

    getSummaries(param) {
      // eslint-disable-next-line no-unused-vars
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Itogo'
          return
        }
        sums[index] = ''

        if (index == columns.length - 1) {
          sums[index] = this.numberWithSpaces(this.reportsData.page_summa)
        }
      })

      return sums
    }
  },

  watch: {
    $route: {
      handler: 'fetchData'
      // immediate: true
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Debet-Kredit qarzdorliklar' }])
    })

    this.$router
      .replace(
        {
          query: {
            year: this.$route.query.year || currentYear,
            month: this.$route.query.month || currentMonth
          }
        }
        // () => {
        //   console.log('error occured');
        //   this.fetchData()
        // }
      )
      .catch(() => {
        // console.log('error catch', err)
        this.fetchData()
      })

    const tableRef = this.$refs.tableRef
    const jamiItogoRef = this.$refs.jamiItogoRef

    // console.log(tableRef.bodyWidth)
    // console.log(jamiItogoRef)

    if (jamiItogoRef && tableRef?.bodyWidth) {
      jamiItogoRef.style = `width: ${
        parseInt(tableRef.bodyWidth) + 1
      }px; border-right: 1px solid #ebeef5; border-bottom: 1px solid #ebeef5`
    }
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>
